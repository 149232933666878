import React, {useEffect, useState, Fragment} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Control from 'components/atoms/Control';
import Input from 'components/forms/elements/Input';
import InputNumberCtl from 'components/forms/elements/InputNumberCtl';
import Select from 'components/forms/elements/Select';
import Switch from 'components/forms/elements/Switch';
import IconToken from 'components/icons/IconToken';
import IconSVG from 'components/icons/IconSVG';
import ColourSelector from 'components/forms/elements/ColourSelector';
import FormAddEditFieldAppendage from 'components/forms/common_forms/FormAddEditFieldAppendage';
import ShellFieldCtlAppendage from 'components/profiles/shells/ShellFieldCtlAppendage';

export default (props) => {
	const [group, setGroup] = useState(null);
	const [groupCtl, setGroupCtl] = useState(null);
	const [ctl, setCtl] = useState(null);
	const [reset, setReset] = useState(null);
	//const [inputPosX, setInputPosX] = useState(null);
	//const [inputPosY, setInputPosY] = useState(null);
	//const [inputs, setInputs] = useState(null);
	const [fonts, setFonts] = useState(null);
	const [optionsFonts, setOptionsFonts] = useState(null);
	const [groupFont, setGroupFont] = useState(null);
	const [groupFontSize, setGroupFontSize] = useState(null);
	const [groupFontStyle, setGroupFontStyle] = useState(null);
	const [groupFontColour, setGroupFontColour] = useState(null);
	
	// HOOKS
	
	useEffect(() => {
		return () => {
			setGroup(null);
		}
	}, []);
	
	useEffect(() => {
		if(props.reset && !reset) {
			setReset(props.reset);
		}
	}, [props.reset]);
	
	useEffect(() => {
		if(props.group && props.group !== group) {
			setGroup(props.group);
			setGroupCtl({...props.group});
		}
	}, [props.group]);
	
	useEffect(() => {
		console.log("here is where we check for reset", reset);
		if(reset && groupCtl) {
			console.log("here is where we received the rest", reset, groupCtl, props.group);	
		}
	}, [reset, groupCtl]);
	
	useEffect(() => {
		console.log("LOOK THERE'S A GROUP OKAY!?!", group);
		if(props.objExists(group, `font.font_size`) &&
			group.font.font_size !== groupFontSize) {
			setGroupFontSize(group.font.font_size);
		}
		
		if(props.objExists(group, `font.font_color`)) {
			const fClr = props.setRGBArray(group.font.font_color);
			setGroupFontColour(fClr);
		}
	}, [group]);
	
	useEffect(() => {
		if(props.fonts && props.fonts !== fonts) {
			setFonts(props.fonts);
		}
	}, [props.fonts]);
	
	useEffect(() => {
		if(Array.isArray(fonts)) {
			const optFonts = 
				fonts.map(font => {
					return (
						{option_name: font.font_name,
						option_value: font.font_name
						}
					)
				});
			if(optFonts !== optionsFonts) {
				setOptionsFonts(optFonts);
			}
		}
	}, [fonts]);
	
	useEffect(() => {
		if (group) {
			setCtl(
				<Fragment>
					<div className="shell-field-ctl" draggable={false} movable={true} >
						<div className="intro">
						<h3>{parseInt(props.objGetValue(group, `group_order`)) > 0 ? `${group.group_order}: ` : ''}{props.objExists(group, `group_name`) ? `${props.capitalizeString(group.group_name)} Group` : `Group ${group.group_id}`}</h3>
						<div className="subtext">
							<b>NOTE:</b> Changes must be saved to take effect.
						</div>
					</div>
					
					<div className="block-border">
						<h4>Group Order</h4>
						<div className="form-row">
							<InputNumberCtl 
								{...props}
								label="Order"
								name="group_order"
								value={props.objExists(group, `group_order`) ?
										group.group_order: 0}
								reset={reset}
								id={group.group_id}
								onChange={updateGroup}
								float={false}
								factor={1}
								orientation="horizontal" />
						</div>
					</div>
					
					<div className="block-border">
						<h4>Group Position</h4>
						{!props.objGetValue(group, `group_parent`) &&
							<div className="form-row">
								<InputNumberCtl 
									{...props}
									label="X"
									name="group_pos_x"
									value={props.objExists(group, `group_pos_x`) ?
											group.group_pos_x : 0}
									reset={reset}
									id={group.group_id}
									onChange={updateGroup}
									float={4}
									factor={.0033}
									orientation="vertical" />
								<InputNumberCtl 
									{...props}
									label="Y"
									name="group_pos_y"
									value={props.objExists(group, `group_pos_y`) ?
											group.group_pos_y : 0}
									reset={reset}
									id={group.group_id}
									onChange={updateGroup}
									float={4}
									factor={.0033}
									orientation="horizontal" />
							</div>
						}
						<div className="form-row">
							<InputNumberCtl 
								{...props}
								label="Width"
								name="group_width"
								value={props.objExists(group, `group_width`) ?
										group.group_width : 0}
								reset={reset}
								id={ group.group_id}
								onChange={updateGroup}
								float={4}
								factor={.0625}
								orientation="vertical" />
							<InputNumberCtl 
								{...props}
								label="Height"
								name="group_height"
								value={props.objExists(group, `group_height`) ?
										group.group_height : 0}
								reset={reset}
								id={group.group_id}
								onChange={updateGroup}
								float={4}
								factor={.0625}
								orientation="horizontal" />
						</div>
						<div className="form-row">
							<Select {...props}
								name="group_align"
								defaultValue={props.objExists(group, 'group_align') ?
												group.group_align : ''}
								label="Alignment"
								option_groups={[
									{options: [
										{option_name: 'Left', option_value: 'L'},
										{option_name: 'Right', option_value: 'R'},
										{option_name: 'Centre', option_value: 'C'},
									]}
								]}
								onChange={updateGroup}
								returnObject={true} />
							<Select {...props}
								name="group_valign"
								defaultValue={props.objExists(group, 'group_valign') ?
												group.group_valign : ''}
								label="Vertical Alignment"
								option_groups={[
									{options: [
										{option_name: 'Top', option_value: 'T'},
										{option_name: 'Middle', option_value: 'M'},
										{option_name: 'Bottom', option_value: 'B'},
									]}
								]}
								onChange={updateGroup}
								returnObject={true} />
						</div>
					</div>
							
					<div className="block-border">
						<h4>Font</h4>
						{optionsFonts &&
						<div className="form-row">
							<Select {...props}
								name="font.font_name"
								defaultValue={props.objExists(group, 'font.font_name') ?
												group.font.font_name : ''}
								label="Font Face"
								option_groups={[
									{options: optionsFonts}
								]}
								onChange={updateGroup}
								returnObject={true} />
						</div>
						}
						<div className="form-row">
							<InputNumberCtl 
								{...props}
								label="Size"
								name="font.font_size"
								value={props.objExists(group, `font.font_size`) ?
										group.font.font_size : ''}
								reset={reset}
								id={group.group_id}
								onChange={updateGroup}
								factor={1}
								orientation="vertical" />
							<InputNumberCtl 
								{...props}
								label="Leading"
								name="group_leading"
								value={props.objExists(group, `group_leading`) ?
										group.group_leading : ''}
								reset={reset}
								id={group.group_id}
								onChange={updateGroup}
								factor={1}
								orientation="vertical" />
						</div>
						<div className="form-row">
							<InputNumberCtl 
								{...props}
								label="Scale"
								name="group_scale"
								value={props.objExists(group, `group_scale`) ?
										group.group_scale : ''}
								reset={reset}
								id={group.group_id}
								onChange={updateGroup}
								factor={1}
								orientation="vertical" />
							<Select {...props}
								name="group_case"
								label="Capitalization"
								defaultValue={props.objExists(group, `group_case`) ?
										group.group_case : ''}
								option_groups={[{options: [
									{option_name: 'unchanged', option_value: ''},
									{option_name: 'UPPERCASE', option_value: 'uc'},
									{option_name: 'lowercase', option_value: 'lc'},
									{option_name: 'Title Case', option_value: 'tc'}
								]}]}
								onChange={updateGroup}
								returnObject={true} />
						</div>
					</div>
							
					<div className="block-border">
						<h4>Colour</h4>
						<div className="flex">
							<div style={{flex: '1 0 10em',
										borderRadius: '0em', 
										paddingRight: '1em',
										marginRight: '1em',
										borderRight: '.125em solid #333333'}}>
								<ColourSelector {...props}
									name = 'font.font_color'
									label="Font Colour"
									value={groupFontColour}
									actionOnChange={updateColour}
									/>
							</div>
							<div style={{flex: '1 0 5em',
										padding: '1em'}}>
								{groupFontColour &&
									<div style={{
										width: '5em',
										height: '5em',
										border: '.125em solid #333333',
										backgroundColor: `rgb(${groupFontColour.r}, ${groupFontColour.g}, ${groupFontColour.b})`
									}}>
									</div>
								}
							</div>
						</div>
					</div>
					
					<div className="ctl">
						<IconToken icon="refresh" tooltip="Reset" action={()=>{loadConfirmation('reset')}} />
						<IconToken icon="close" tooltip="Cancel" action={()=>{loadConfirmation('cancel')}} />
						<IconToken icon="save" tooltip="Save" action={()=>{loadConfirmation('update')}} />
					</div>
				</div>
				</Fragment>
			);
		}
	}, [group, optionsFonts]);
	 
	// FUNCTIONS
	
	function resetGroup(closeCtl = false) {
		props.adminSetValue(`reload.product`, true);
	}
	
	function updateGroup(params, other,) {
		console.log("Select Changed", params);
		if(!props.objExists(params, 'id')) {
			params = {...params, id:group.group_id};	
		}
		
		if(params.value === false) {
			params = {...params, value: 0}
		}else if(params.value === true) {
			params = {...params, value: 1}
		}
		
		if(params.name === 'group_font_size' &&
			groupFontSize !== parseFloat(params.value)) {
			setGroupFontSize(parseFloat(params.value));
		}
		props.actionUpdate &&
		props.actionUpdate(params);
	}
	
	function loadConfirmation(type) {
		props.actionConfirm &&
		props.actionConfirm(type, 'group');
	}
	
	function updateColour(name, format, value) {
		if(format === 'rgb' && groupFontColour !== value) {
			updateGroup(
				{name: name,
				value: `[${value.r}, ${value.g}, ${value.b}]`,
				id: group.group_id}
			);
			setGroupFontColour(value);
		}
	}
	
	return ctl
}