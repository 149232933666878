import React, {useEffect, useState, Fragment} from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import IconToken from 'components/icons/IconToken';
import Form from 'components/forms/Form';
import InputDate from 'components/forms/elements/InputDate';
import Select from 'components/forms/elements/Select';
import Button from 'components/forms/elements/Button';
import Switch from 'components/forms/elements/Switch';
import OrganizationSelector from 'components/profiles/orgs/OrganizationSelector';

export default (props) => {
	const [userID, setUserID] = useState(null);
	const [token, setToken] = useState(null);
	const [organizationID, setOrganizationID] = useState(null);
	const [organizationName, setOrganizationName] = useState(null);
	const [languageCode, setLanguageCode] = useState(null);
	const [userAccess, setUserAccess] = useState(null);
	const [organizations, setOrganizations] = useState(null);
	const [coreOrg, setCoreOrg] = useState(null);
	const [orders, setOrders] = useState(null);
	const [dateStart, setDateStart] = useState(null);
	const [dateEnd, setDateEnd] = useState(null);
	const [queryStart, setQueryStart] = useState(null);
	const [queryEnd, setQueryEnd] = useState(null);
	const [completed, setCompleted] = useState(false);
	const [archived, setArchived] = useState(false);
	const [years, setYears] = useState([]);
	const [projectID, setProjectID] = useState(null);
	const [qParams, setQParams] = useState(null);
	const [loading, setLoading] = useState(null);
	const [response, setResponse] = useState(null);
	const [projects, setProjects] = useState(null);
	const [dateCurrent, setDateCurrent] = useState(new Date());
	const [activeFilter, setActiveFilter] = useState(null);
	const [filter, setFilter] = useState(null);
	const [query, setQuery] = useState(null);
	
	// HOOKS
	useEffect(() => {
		// SET DEFAULT START DATE (START OF CURRENT MONTH);
		let sDate = new Date();
		sDate.setHours(0)
		sDate.setMinutes(0);
		sDate.setSeconds(0);
		sDate.setDate(1);
		sDate.setMonth(sDate.getMonth());
		setDateStart(sDate);
		
		// SET YEAR OPTIONS FROM 2020 TO PRESENT
		let oYears = [];
		for(let oYear = 2020; oYear <= parseInt(sDate.getFullYear()); oYear++) {
			oYears.push({option_name: oYear, option_value: oYear});
		}
		setYears([...oYears]);
				
		// SET DEFAULT END DATE (+ 1 Month);
		let eDate = new Date(sDate);
		//eDate.setMonth(sDate.getMonth()+1, 1);
		
		// SET DEFAULT END DATE (last day of current month)
		eDate.setDate(new Date(sDate.getYear(), sDate.getMonth()+1, 0).getDate());
		
		setDateEnd(eDate);
		
		setActiveFilter('month');
		setFilter('month');
		setQuery(true);
		
	}, []);
	
	
	useEffect(() => {
		if(props.userID) {
		  setUserID(props.userID)
		}
	}, [props.userID]);
	  
	useEffect(() => {
		if(props.token) {
		  setToken(props.token)
		}
	}, [props.token]);
	
	useEffect(() => {
		if(props.organizationID && organizationID !== props.organizationID) {
		  setOrganizationID(props.organizationID);
		}
	}, [props.organizationID]);
	  
	useEffect(() => {
		props.organizationName &&
		props.organizationName !== organizationName &&
		setOrganizationName(props.organizationName);
	}, [props.organizationName]);
	  
	useEffect(() => {
		if(props.languageCode && languageCode !== props.languageCode) {
		  setLanguageCode(props.languageCode);
		}
	}, [props.languageCode]);
	
	useEffect(() => {
		if(organizationID === 1 &&
			Array.isArray(props.objGetValue(props.admin, `organizations`)) &&
			props.admin.organizations !== organizations) {
			setOrganizations(props.admin.organizations);
		}
	}, [organizationID, props.admin.organizations]);
	
	useEffect(() => {
		if(props.coreOrg && props.coreOrg !== coreOrg) {
			setCoreOrg(props.coreOrg);
		}else if(!props.coreOrg && coreOrg) {
			setCoreOrg(null);
		}
	}, [props.coreOrg]);
	  
	useEffect(() => {
		if(props.userAccess && props.userAccess !== userAccess) {
		  setUserAccess(props.userAccess);
		} 
	}, [props.userAccess]);
	
	useEffect(() => {
		//console.log("Completed:", completed, "Archived:", archived, "Query:", query);
		
		if(dateStart && dateEnd) {
			const paramsQuery = [
				{name: 'process', value: 'query_projects'},
				{name: 'user_id', value: userID},
				{name: 'token', value: token},
				{name: 'organization_id', value: coreOrg ? coreOrg : organizationID},
				{name: 'date_start', value: dateStart ? props.dateDisplay(dateStart, 'en', 'db') : null},
				{name: 'date_end', value: dateEnd ? props.dateDisplay(dateEnd, 'en', 'db') : null},
				{name: 'project_id', value: null},
				{name: 'project_completed', value: completed},
				{name: 'project_archived', value: archived}
			];
			if(query) {
				//console.log("UPdateding Query");
				props.adminLoadState(paramsQuery, 'orders', 'query.projects');
				setQueryStart(new Date(dateStart));
				setQueryEnd(new Date(dateEnd));
				setLoading(true);
				setQuery(false);
				setQParams(paramsQuery);	
			}
		}
	}, [dateStart, dateEnd, query, coreOrg, completed, archived]);
	
	useEffect(() => {
		setQuery(true);
	}, [completed]);
	
	useEffect(() => {
		setQuery(true);
	}, [archived]);
	
	useEffect(() => {
		//console.log("CHECKING QUERY", props.admin.query);
		if(props.objExists(props.admin, `query.projects`)) {
			//console.log("SOMETHING CHANGED", props.admin.query);
			if(loading) {
				setLoading(false);	
			}
			
			if(Array.isArray(props.admin.query.projects) && 
				props.admin.query.projects !== projects) {
				setProjects(props.admin.query.projects);
				if(response) { 
					setResponse(null);
				}
			}else{
				if(projects) {
					setProjects(null);
				}
				setResponse(props.admin.query.projects);
			}
		}else if(projects) {
			setProjects(null);
		}
	}, [props.admin.query]);
	
	function applyFilter(type) {
		setFilter(type);
		setQuery(true);
	}
	
	function updateFilters(params) {
		//console.log("update filters", params);
		switch(params.name) {
			case 'month':
				if(dateStart.getMonth() !== parseInt(params.value)-1) {
					let newDateStart = new Date(dateStart);
					let newDateEnd = new Date(dateStart);
					newDateStart.setMonth(parseInt(params.value)-1);
					setDateStart(newDateStart);
					//newDateEnd.setMonth(newDateStart.getMonth()+1);
					newDateEnd.setMonth(parseInt(params.value)-1);
					newDateEnd.setDate(new Date(newDateEnd.getYear(), newDateEnd.getMonth()+1, 0).getDate());
					setDateEnd(newDateEnd);
					if(filter !== 'month') {
						setFilter('month');
					}
				}
				break;
			case 'year':
				if(dateStart.getFullYear() !== parseInt(params.value)) {
					let newDateStart = new Date(dateStart);
					let newDateEnd = new Date(dateStart);
					newDateStart.setFullYear(parseInt(params.value));
					setDateStart(newDateStart);
					newDateEnd.setFullYear(parseInt(params.value));
					newDateEnd.setDate(new Date(newDateEnd.getYear(), newDateEnd.getMonth()+1, 0).getDate());
					//console.log("newDateStart", newDateStart);
					//console.log("newDateEnd", newDateEnd);
					setDateEnd(newDateEnd);
				}
				break;
			case 'date-start':
				if(params.value) {
					let newRangeStart = props.setDate(params.value);
					if(dateStart !== newRangeStart) {
						setDateStart(newRangeStart);
					}
				}
				break;
			case 'date-end':
				if(params.value) {
					let newRangeEnd = props.setDate(params.value);
					if(dateEnd !== newRangeEnd) {
						setDateEnd(newRangeEnd);
					}
				}
				break;
			default:
				break;
				
		}
	}
	
	function selectOrg(org) {
		if((parseInt(org) > 1 && parseInt(org) !== coreOrg) ||
			(parseInt(org) <= 1 && coreOrg)) {
			setCoreOrg(parseInt(org) > 1 ? parseInt(org) : null);
			if(props.actionSelectOrg) {
				props.actionSelectOrg(org);
			}
			setQuery(true);
		}
	}
	
	//console.log("TEST projects", projects);
	
	return (
		<div className="">
			<h2>Order Report</h2>
			<form style={{maxWidth: '100%'}}>
				<h3>Filter By</h3>
				{organizations &&
					<OrganizationSelector
						{...props}
						organizations={organizations}
						orgID={coreOrg}
						action={selectOrg}
						skipCore={true}
						userAccess={userAccess} />
				}
				<div className="block-border">
					<h3>Month
						<IconToken 
							icon={activeFilter === 'month' ? 'collapse' : 'expand'}
							action={()=>{setActiveFilter(activeFilter === 'month' ? null: 'month')}}
							className="p" />
					</h3>
					<div className={`expandable${activeFilter === 'month' ? ' active' : ''}`}>
						<div className="form-row">
							<Select {...props}
								className="sml"
								name="month"
								label="Month"
								option_groups={[{
									options: [
										{option_name: 'January', option_value: 1},
										{option_name: 'February', option_value: 2},
										{option_name: 'March', option_value: 3},
										{option_name: 'April', option_value: 4},
										{option_name: 'May', option_value: 5},
										{option_name: 'June', option_value: 6},
										{option_name: 'July', option_value: 7},
										{option_name: 'August', option_value: 8},
										{option_name: 'September', option_value: 9},
										{option_name: 'October', option_value: 10},
										{option_name: 'November', option_value: 11},
										{option_name: 'December', option_value: 12}
									]
								}]}
								defaultValue={dateStart ? parseInt(dateStart.getMonth()+1) : null}
								onChange={updateFilters}
								returnObject={true} />
							<Select {...props}
								className="sml"
								name="year"
								label="Year"
								option_groups={[{
									options: years
								}]}
								defaultValue={dateStart ? parseInt(dateStart.getFullYear()) : null}
								onChange={updateFilters}
								returnObject={true} />
						
						</div>
						<div className='form-row'>
							<Button icon="filter" 
									label="Filter by Month"
									btnClass="info-bg mini"
									btnAction={()=>{applyFilter('month')}} />
						</div>
					</div>
				</div>
				
				<div className="block-border">
					<h3>Date Range
						<IconToken 
							icon={activeFilter === 'date range' ? 'collapse' : 'expand'}
							action={()=>{setActiveFilter(activeFilter === 'dateRange' ? null: 'date range')}}
							className="p" />
					</h3>
					<div className={`expandable${activeFilter === 'date range' ? ' active' : ''}`}>
						<div className="form-row">
							<InputDate {...props}
								name="date-start"
								label="Start Date"
								minDate={`2019-01-01 00:00:00`}
								maxDate={props.dateDisplay(dateCurrent, 'en', 'db')}
								value={dateStart ? props.dateDisplay(dateStart, 'en', 'db') : null}
								onChange={updateFilters} />
						
						</div>
						<div className="form-row">
							<InputDate {...props}
								name="date-end"
								label="End Date"
								minDate={`2019-01-01 00:00:00`}
								value={dateEnd ? props.dateDisplay(dateEnd, 'en', 'db') : null}
								onChange={updateFilters} />
						
						</div>
						<div className='form-row'>
							<Button icon="filter" 
									label="Filter by Range"
									btnClass="info-bg mini"
									btnAction={()=>{applyFilter('date range')}} />
						</div>
					</div>
				</div>
				<div className="form-row" style={{marginTop: '2em'}}>
					<Switch label="Completed" value={completed} action={()=>{setCompleted(completed ? false : true)}} />
					<Switch label="Archived" value={archived} action={()=>{setArchived(archived ? false : true)}} />
				</div>
			
			</form>
			<div className="title-block">
				{filter &&
					<h4>{`Filter By ${props.capitalizeString(filter)}`}</h4>
				}
				{queryStart &&
					<h3>{`${props.dateDisplay(queryStart, 'en', filter === 'month' ? 'month-year' : 'short', true)}${filter === 'date range' ? ` - ${props.dateDisplay(queryEnd, 'en', 'short', true)}` : ''}`}</h3>	
				}
			</div>
			
			{loading && 
				<div>Report is loading</div>
			}
			{response &&
				<div>{response}</div>
			}
			
			{projects && 
				<div>{`${projects.length} Projects`}</div>
			}
			
			<div className="form-row">
				<a className="link-btn" href={`https://api.aecore.app/files/xlsx.php?query=${btoa(`?type=projects&user_id=${userID}&token=${token}&organization_id=${coreOrg > 0 ? coreOrg : 0}&core_user=0&project_id=0&date_start=${dateStart ? props.dateDisplay(dateStart, 'en', 'db') : null}&date_end=${dateEnd ? props.dateDisplay(dateEnd, 'en', 'db') : null}&project_archived=${archived}&project_completed=${completed}`)}`}>Download Report</a>
			</div>
			
			{Array.isArray(projects) &&
			<div className="grid" style={{gridTemplateColumns: 'auto auto auto auto 1fr auto'}}>
				<div className="grid-header fixed">Project No.</div>
				<div className="grid-header fixed">Date</div>
				<div className="grid-header fixed">Organization</div>
				<div className="grid-header fixed">Ordered by</div>
				<div className="grid-header fixed">Order Item(s)</div>
				<div className="grid-header fixed">Status</div>
								
				{projects.map((project, index) => {
					if(project) {
						return (
							<Fragment key={`aoqp${index}`}>
								<div className="column">{project.project_number}</div>
								<div className="column">{props.dateDisplay(project.project_created, languageCode, 'numeric')}</div>
								<div className="column">{project.organization_name}</div>
								<div className="column">{project.user_name}</div>
								<div className="column">
									{ReactHtmlParser(`<div>${project.order_items.replaceAll('\n', '</div><div style="margin-top: .5em;">')}</div>`)}</div>
								<div className="column">{project.project_status}</div>
							</Fragment>
						)
					}
				})}
				
			</div>
			}
		</div>
	)
}